import React from "react";
import Slider from "react-slick";
import SliderWrapper from "./SliderStyle.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SamplePrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i
        className="fa-solid fa-angle-left p-1 px-2 bg-white ml-4"
        style={{ border: "1px solid gray" }}
      ></i>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i
        className="fa-solid fa-angle-right  p-1 px-2 bg-white ml-4"
        style={{ border: "1px solid gray" }}
      ></i>
    </div>
  );
};

const SliderComponent = ({ children }) => {
  const settings = {
    arrows: true,
    speed: 500,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 4,
          infinite: children.length > 3 ? true : false,
          dots: true,
        },
      },
      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: children.length > 3 ? true : false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: children.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: children.length > 2 ? true : false,
        },
      },
    ],
    appendDots: (dots) => <ul>{dots}</ul>,
  };
  return (
    <div>
      <SliderWrapper>
        <Slider {...settings}>{children}</Slider>
      </SliderWrapper>
    </div>
  );
};

export default SliderComponent;
