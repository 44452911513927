import { createSlice } from "@reduxjs/toolkit";

export const getConstants = createSlice({
  name: "getConstants",
  initialState: {
    sellerId: "",
  },
  reducers: {
    setSellerId: (state, action) => {
        state.sellerId = action.payload;
    },
  },
});

export const { setSellerId } = getConstants.actions;

export default getConstants.reducer;
