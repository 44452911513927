import React from "react";
import Slider from "react-slick";
import SliderWrapper from "./SliderStyle.js";

const SamplePrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i
        className="fa-solid fa-angle-left p-1 px-2 bg-white ml-4"
        style={{ border: "1px solid gray" }}
      ></i>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i
        className="fa-solid fa-angle-right  p-1 px-2 bg-white ml-4"
        style={{ border: "1px solid gray" }}
      ></i>
    </div>
  );
};

const SliderComponent = ({ children }) => {
  const settings = {
    dots: true,
    arrows: true,
    speed: 500,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          dots: true,
        },
      },
      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          infinite: true,
        },
      },
    ],
    appendDots: (dots) => <ul>{dots}</ul>,
  };
  return (
    <div>
      <SliderWrapper>
        <Slider {...settings}>{children}</Slider>
      </SliderWrapper>
    </div>
  );
};

export default SliderComponent;
