import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSearchTypology } from "../../redux/Slice/AllMarketplace";
import { Link } from "react-router-dom";
const TypologyCard = ({ name, image }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleTypologyNavigate = () => {
    dispatch(setSearchTypology(name));
    navigate("/marketplace");
  };

  return (
    <Link to="/marketplace" state={{ data: name }}>
      <div >
        <div className="collection-name-wrap m-3 v-box">
          <img src={image} />
          <h4>{name}</h4>
        </div>
      </div>
    </Link>
  );
};

export default TypologyCard;
