import NFT7 from "../utils/Abis/NFT7.json";
import WETH from "../utils/Abis/WETH.json";
import NFT13 from "../utils/Abis/NFT13.json";
import Resale from "../utils/Abis/Resale.json";

export const prodConfiguration = {
  baseShareUrl: "http://quytech.in:3327/",
  baseUrl: "https://backend.metaprops.io/api/V1",
  getRole: "/role",
  registerClient: "/user",
  logout: "/disconnect",
  pushNotification:'/push-notifications',
  getUserProfile: "/Profile",
  updateRefreshToken: "/refreshToken",
  updateUserProfile: "/user-profile",
  registerCreator: "/register-creator",
  updateCreator: "/creator",
  getTerms: "/getTermsAndCondition",
  getPrivacyPolicy: "/getPrivacyPolicy",
  getHelp: "/getHelpAndGuidance",
  getFaq: "/getFaq",
  getTypology: "/listOfTypology",
  getFeaturedCollection: "/listOfFeaturedCollection",
  getFeature: "/listOfFeaturedCreators",
  getFeaturedNft: "/listOfFeaturedNfts",
  getAllCreator: "/listOfAllCreators?search",
  getAllCollection: "/listOfAllCollection",
  addNft: "/nft",
  editNft: "/nft",
  getAllMarketPlace: "/list-all-marketplace",
  getCollectionDetail: "/listNftInCollection?collection_id=",
  getTypology: "/listOfTypology",
  getCollection: "/collection",
  getNFTDetails: "/nft-data?id=",
  getActivity: "/activity",
  postReport: "/addReport",
  setNewsletter: "/addNewsLetter",
  getBannerImage: "/bannerImage",
  getSearch: "/search?search=",
  getCreatorRank: "/creatorRanking?duration",
  getOwnedNft: "/ownedNft",
  getFavoritedNft: "/listOfFavouriteNft",
  getTypologySearch: "/searchTypology",
  sellNFT: "/sale-nft",
  getCommision: "/comission",
  getLikeStatus: "/checkFavourite?",
  addFav: "/addFavourite",
  uploadIpfs: "/ipfs",
  getNftOfCreator: "/getNftOfCreator",
  getCreatorData: "/getNftOfCreator?creator_id=",
  marketPlaceFilter: "/list-all-marketplace",
  addZip: "/zip",
  onSaleApi: "/onSale",
  soldApi: "/sold",
  getCreatorActivity: "/creatorActivity",
  getUSDPrice: "/USDPrice?symbol=",
  updateViewCount: "/view",
  deleteNFT: "/nftDelete",
  editNftPrice: "/price",
  getFloorPriceCreator: "/creatorData",
  creatorFilter: "/filter",
  getCreatorCalc: "/creatorData",
  getNotification: "/allnotifications",
  getClearAllNotification: "/clearAllNotifications",
  deleteNotification: "/deleteNotification",
  getCollectionInfo: "/collectionDetails?id=",
  collectionFilter: "/listNftInCollection?collection_id=",
  getCollectionTotal: "/colllectionData",
  updateVoucher: "/signature",
  purchaseNFT: "/purchase",
  getListing: "/listing?token_count=",
  featuredTypology: "/listOfFeaturedTypology",
  checkExpiredNftSale: "/checkExpiredNftSale",
  removeCreatorNft: "/removeNft",
  cancelNft: "/removeFromSale",
  searchCustomProps: "/searchCustomProperties",
  searchCustomPropsUser: "/searchCustomPropertiesUser",
  getWithdrawnInfo: "/getWithDraw",
  updateWithdraw: "/update_withdraw",
  deleteCustomProperty: "/deleteCustomerProperties",
  searchCustomPropertiesCollection:"/searchCustomPropertiesCollection",
	searchCreatorInCollection:"/searchCreatorInCollection"
};

export const prodAbiConfig = {
  nft7: NFT7,
  nft13: NFT13,
  resale: Resale,
  weth: WETH,
  chainId: 137,

  // nftAddress: "0xc4aa2f24e7e35287cddafde6fea1be7da5bd8867",
  // nftAddress: "0x0CA19516cC49e7744e8E60364Dd4DbEED7E7d5E1",

  // nftAddress: "0x435be1F0eD8b8B97B0795e73e61e57dF37F6d21b",
  nftAddress: "0xC4A1aEb4d3Cf4571e270659fEd95f51d5C39469e", //using for mainnet

  // resaleAddress: "0x1366520116843280429a60C04b6fc2C3F199E469",
  resaleAddress: "0x8f2e345DBB41AF7Aa6C25d7D94b2F4B5345B285f", // using for mainnet

  zeroAddress: "0x0000000000000000000000000000000000000000",

  // tokenAddress: "0xf4555e485d6575Edf3D255d52b2f51dE8529c39c",
  tokenAddress: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",

};
