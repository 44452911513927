import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA9LXIkcdBT7IQPLTd57TJkPiz_5RrcTvs",
  authDomain: "metaprops-web.firebaseapp.com",
  projectId: "metaprops-web",
  storageBucket: "metaprops-web.appspot.com",
  messagingSenderId: "775777410031",
  appId: "1:775777410031:web:408bca7c0b393634cc741a",
  measurementId: "G-X1ZCN35EWF",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;
